import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    approvalCode: {
        isLoaded: false,
        isValid: false,
        value: "",
        error: ""
    },
    company: {

    },
    appointment: {

    },

}

export const GET_CSRF = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}v1/csrf`, { withCredentials: true })
        if (response?.status === 200) {
            localStorage.setItem('csrfToken', response?.data?.csrf_token)
        }
    } catch (error) {
        console.log(error);
    }
}

export const startValidateExternalApprovalCode = createAsyncThunk("commonSlice/startValidateExternalApprovalCode", async (body, { dispatch, rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_VISITOR_URL}v1/externalhost/approvalcode/validate`, body, {
            headers: {
                'x-csrf-token': localStorage.getItem('csrfToken')
            },
            withCredentials: true
        })
        if (response.status === 200) {
            dispatch(setIsLoaded({ isValid: true }))
            localStorage.setItem('encryptedCode', body?.encryptedCode)
            return {
                value: response?.data?.value,
                companyLogo: response?.data?.companyLogo,
                appointment: response?.data?.appointment,
                tenantCode: response?.data?.tenantCode,
                buildingTimezone: response?.data?.buildingTimezone
            }
        }
        else {
            console.log({ response })
        }
    }
    catch (err) {
        if (err?.response?.status === 400 && err?.response?.data?.error === "Invalid csrf token") GET_CSRF()
        else dispatch(setIsLoaded({ isValid: false, error: 'Oops, the link has expired.' }))
    }
})

export const startUpdateAppointment = createAsyncThunk("commonSlice/startUpdateAppointment", async (data, { dispatch }) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_VISITOR_URL}v1/externalhost/appointment/${data?.appointmentId}?buildingId=${data?.buildingId}`, data?.bodyObj, {
            headers: {
                'x-csrf-token': localStorage.getItem('csrfToken')
            },
            withCredentials: true
        })
        if (response?.status === 200) {
            // setIsApprovalSubmitted(true)
            return response?.data
        } else {
            console.log(response)
        }
    } catch (error) {
        if (error?.response?.status === 400 && error?.response?.data?.error === "Invalid csrf token") GET_CSRF()
        else if (error?.response?.status === 401) {
            dispatch(setIsLoaded({ isValid: false, error: 'Oops, the link has expired.' }))
        }
        console.log(error)
    }
})


const commonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {
        setIsLoaded: (state, action) => {
            state.approvalCode.isLoaded = true
            state.approvalCode.isValid = action.payload?.isValid
            state.approvalCode.error = action.payload?.error
        }
    },
    extraReducers(builder) {
        builder.addCase(startValidateExternalApprovalCode.fulfilled, (state, action) => {
            if (action.payload) {
                state.approvalCode.value = action.payload?.value
                state.company.logo = action.payload?.companyLogo
                state.company.tenantCode = action.payload?.tenantCode
                state.appointment = action.payload?.appointment
                state.buildingTimezone = action.payload?.buildingTimezone
            }

        })
        builder.addCase(startUpdateAppointment.fulfilled, (state, action) => {
            if (action.payload) {
                state.appointment = action.payload
            }

        })
    }
})

export const { setIsLoaded } = commonSlice.actions

export default commonSlice.reducer
