import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startUpdateAppointment } from '../../Redux/Slice/commonSlice'

const NotesBox = ({ status }) => {
    const [notesLabel, setNotesLabel] = useState(status === "accepted" ? "Notes (Optional)" : status === 'cancelled' ? "Reason to cancel (Optional)" : "")
    const [notesText, setNotesText] = useState("")
    const [maxTextLength, setMaxTextLength] = useState(100)

    const dispatch = useDispatch()

    const company = useSelector(state => state.common?.company)
    const appointment = useSelector(state => state.common?.appointment)
    const approvalCode = useSelector(state => state.common?.approvalCode)

    useEffect(() => {
        if (status) setNotesLabel(status === "accepted" ? "Notes (Optional)" : status === 'cancelled' ? "Reason to cancel (Optional)" : "")
        setNotesText("")
    }, [status])

    const handleTextChange = (e) => {
        if (e.target?.value?.length <= maxTextLength) setNotesText(e.target?.value)
    }

    return (
        <Box sx={{ height: { xs: '60%', sm: '70%', md: "90%" }, display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                <TextField
                    id="outlined-multiline-flexible"
                    label={notesLabel}
                    multiline
                    maxRows={2}
                    sx={{ width: { xs: '300px', sm: '400px', md: "500px" } }}
                    value={notesText}
                    onChange={handleTextChange}
                />
                <Typography sx={{ alignSelf: "flex-end", mt: 1, color: notesText?.length === maxTextLength ? 'red' : 'black' }} variant='caption'>{notesText?.length}/{maxTextLength}</Typography>
            </Box>
            <Button
                sx={{
                    width: { xs: '300px', sm: '400px', md: "500px" },
                    bgcolor: '#F46326',
                    color: '#fff',
                    textTransform: 'none',
                    ":hover": {
                        bgcolor: '#dc4d28'
                    },
                }}
                onClick={() => {
                    dispatch(startUpdateAppointment({
                        bodyObj: {
                            externalApprovalCode: approvalCode?.value,
                            status,
                            tenantCode: company?.tenantCode,
                            [notesLabel === "Notes (Optional)" ? "notes" : "cancellationReason"]: notesText?.trim()
                        },
                        appointmentId: appointment?._id,
                        buildingId: appointment?.building?._id
                    }))
                }}
            >Submit</Button>
        </Box>
    )
}

export default NotesBox